@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .navbar-elem {
        @apply my-2 mx-4 lg:mx-6 font-semibold hover:text-yellow-birdhouse;
    }
    .section {
        @apply px-4 pt-6 md:px-6 md:pt-9 lg:px-14 lg:pt-20;
    }
    .title {
        @apply text-lg md:text-2xl font-bold mb-3 md:mb-3 lg:mb-6;
    }
    .text {
        @apply text-justify text-sm md:text-lg leading-9 tracking-wider md:leading-9 lg:leading-lgt hd:leading-9;
    }
}